import { gql } from '@apollo/client/core';
import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export const useRequests = (variables) => {
  const { error } = useNotification();
  const { result, loading, refetch, onError } = useQuery(REQUESTS_QUERY, variables);

  const requestsConnection = computed(() => result.value?.requests ?? { nodes: [], totalCount: 0 });
  const requests = computed(() => requestsConnection.value.nodes);
  const totalCount = computed(() => requestsConnection.value.totalCount);

  onError((err) => {
    console.error('useRequests', err);
    error();
  });

  return {
    requests,
    totalCount,
    loading,
    refetch,
  };
};

export const REQUESTS_QUERY = gql`
  query requests(
    $limit: Int
    $offset: Int
    $sourceBusinessId: String
    $targetBusinessId: String
    $isAwaitingResponse: Boolean
    $closed: Boolean
    $templates: [String]
    $sort: Sort
  ) {
    requests(
      limit: $limit
      offset: $offset
      sourceBusinessId: $sourceBusinessId
      targetBusinessId: $targetBusinessId
      isAwaitingResponse: $isAwaitingResponse
      closed: $closed
      templates: $templates
      sort: $sort
    ) {
      totalCount
      nodes {
        id
        sourceBusinessId
        sourceBusiness {
          id
          name
          number
        }
        targetBusinessId
        targetBusiness {
          id
          name
          number
        }
        details
        createdBy {
          id
          firstName
          lastName
          email
        }
        createdAt
        activeBy {
          id
          firstName
          lastName
          email
        }
        activeAt
        closed
        closedAt
        closedBy {
          id
          firstName
          lastName
          email
        }
        isAwaitingResponse
        template
        templateData {
          balanceAlignment {
            reconciliationPeriodStart
            reconciliationPeriodEnd
            reconciliationDate
          }
          balanceAlignmentCheckNewInvoices {
            reconciliationPeriodStart
            reconciliationPeriodEnd
            reconciliationDate
          }
          balanceAlignmentMissingDocuments {
            documents {
              docNumber
              docType
              docDate
            }
          }
          balanceAlignmentReconciliationStatement {
            reconciliationPeriodStart
            reconciliationPeriodEnd
            reconciliationDate
          }
        }
        responses {
          attachments
          filePathUrls
          text
          reject {
            text
            createdAt
          }
          createdBy {
            id
          }
          createdAt
        }
      }
    }
  }
`;
