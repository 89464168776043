import { gql } from '@apollo/client/core';

export const BUSINESS_CREATE_MUTATION = gql`
  mutation businessCreateMutation($businessCreateParams: BusinessCreateInput!) {
    businessCreate(createParams: $businessCreateParams) {
      id
      name
    }
  }
`;
