<template>
  <div class="container">
    <div class="dashhead">
      <div class="dashhead-titles">
        <h2 class="dashhead-title">Restaurants</h2>
      </div>
    </div>

    <table class="table table-hover">
      <thead>
        <tr>
          <th>Name</th>
          <th>Documents to Record</th>
          <th>Documents to Classify</th>
          <th>Documents to Organize</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="restaurants.length">
          <tr
            v-for="restaurant in restaurants"
            :key="restaurant._id"
            @click="routeToRestaurant(restaurant._id, 'overview')"
          >
            <td>{{ restaurant.name }}</td>
            <td @click.stop="routeToRestaurant(restaurant._id, 'recordDocuments')">
              <template v-if="getCount(restaurant._id, 'record', 'document')">
                {{ getCount(restaurant._id, 'record', 'document') }}
              </template>
              <i v-else class="el-icon-check text-success"></i>
            </td>
            <td @click.stop="routeToRestaurant(restaurant._id, 'documents')">
              <template v-if="getCount(restaurant._id, 'upload', 'document')">
                {{ getCount(restaurant._id, 'upload', 'document') }}
              </template>
              <i v-else class="el-icon-check text-success"></i>
            </td>
            <td @click.stop="routeToRestaurant(restaurant._id, 'organizeDocuments')">
              <template v-if="getCount(restaurant._id, 'organize', 'document')">
                {{ getCount(restaurant._id, 'organize', 'document') }}
              </template>
              <i v-else class="el-icon-check text-success"></i>
            </td>
          </tr>
        </template>
        <tr v-else>
          <td colspan="4">Wow! Brand new deployment, yet to add restaurants</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script type="text/javascript">
import { gql } from '@apollo/client/core';

import { Restaurants } from '@/imports/api/collections';

import { getTasksOverview } from './compositions/tasks-operations';

export default {
  data() {
    return {
      dialogVisible: false,
      tasksOverview: [],
      taskAggregations: [],
    };
  },
  async created() {
    this.tasksOverview = await getTasksOverview();
  },
  methods: {
    routeToRestaurant(restaurantId, routeName) {
      this.$router.push({ name: routeName, params: { tenantId: restaurantId } });
    },
    getCount(businessId, type, domain) {
      if (!domain) return this.tasksOverview.find((o) => o.restaurantId === businessId)?.[type] ?? 0;

      return (
        this.taskAggregations.find((agg) => agg.domain === domain && agg.type === type && agg.businessId === businessId)
          ?.count ?? 0
      );
    },
  },
  apollo: {
    taskAggregations: {
      query: gql`
        query taskAggregation($filters: TaskAggregationFilters, $options: TaskAggregationOptions) {
          taskAggregation(filters: $filters, options: $options) {
            businessId
            domain
            type
            count
          }
        }
      `,
      update: (data) => data.taskAggregation,
      variables() {
        return { filters: { completed: false }, options: { groupBy: ['domain', 'type', 'businessId'] } };
      },
    },
  },
  meteor: {
    $subscribe: {
      'restaurants.all': [],
    },
    restaurants() {
      return Restaurants.find({});
    },
  },
};
</script>
