<template>
  <div>
    <h3 class="mb-5">
      {{ `${$t('requests.title')}  ${$t('requests.tabs.inReview')}` }}
    </h3>
    <RequestsTable
      :requests-loading="loading"
      :requests="requests"
      :page-limit="PAGE_LIMIT"
      :current-page="currentPage"
      :action-allowed="true"
    />
    <div class="d-flex justify-content-end my-3">
      <el-pagination
        v-if="totalCount > PAGE_LIMIT"
        small
        layout="prev, pager, next, jumper"
        background
        :current-page.sync="currentPage"
        :page-size="PAGE_LIMIT"
        :page-count="Math.ceil(totalCount / PAGE_LIMIT)"
        :total="totalCount"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, getCurrentInstance } from 'vue';

import { useTenancy } from '@/modules/auth';

import { RequestsTable } from '../components';
import { useRequests } from '../compositions/useRequests';

const PAGE_LIMIT = 25;

export default {
  name: 'InReviewRequests',
  components: {
    RequestsTable,
  },
  setup() {
    const { currentTenant } = useTenancy();
    const root = getCurrentInstance().proxy;
    const currentPage = ref(1);

    const requestTypeFilter = computed({
      get: () => root.$route.query.requestType,
    });

    const customerFilter = computed({
      get: () => root.$route.query.customer,
    });

    const sortBy = computed({
      get: () => root.$route.query.sortBy,
    });

    const orderBy = computed({
      get: () => root.$route.query.orderBy,
    });

    const variables = computed(() => ({
      limit: PAGE_LIMIT,
      offset: (currentPage.value - 1) * PAGE_LIMIT,
      targetBusinessId: currentTenant.value.id,
      sourceBusinessId: customerFilter.value,
      closed: false,
      isAwaitingResponse: false,
      templates: requestTypeFilter.value
        ? [requestTypeFilter.value]
        : ['balanceAlignment', 'balanceAlignmentReconciliationStatement', 'balanceAlignmentMissingDocuments'],
      sort:
        sortBy.value && orderBy.value
          ? {
              sortBy: sortBy.value,
              orderBy: orderBy.value === -1 ? 'asc' : 'desc',
            }
          : {
              sortBy: 'responseCreatedAt',
              orderBy: 'asc',
            },
    }));

    watch([requestTypeFilter, customerFilter], () => (currentPage.value = 1), { immediate: true });

    const { requests, totalCount, loading } = useRequests(variables);

    return {
      PAGE_LIMIT,
      requests,
      totalCount,
      loading,
      currentPage,
      sortBy,
      orderBy,
    };
  },
};
</script>
